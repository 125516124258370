import { useState, useEffect } from "react"
import {
  ErrorCodes,
  getDetailsFromForm,
  isFormCompleted,
} from "@common/lib/forms.utils"
import Input from "./input"
import ErrorMessage from "components/common/errorMessage"
import Range from "@common/forms/inputs/range"
import MultiSelect from "@common/forms/inputs/multiSelect"
import ComboboxStyled from "@common/dropdowns/comboboxStyled"
import Button from "@common/buttons/button"
import Svg from "components/common/svg"
import { deepCopyObject, hitUrl } from "@common/lib/util"
import AccordionSingle from "@common/accordion/accordionSingle"
import { Header } from "@common/header/headerUnit"
import ReCAPTCHA from "react-google-recaptcha"
import PaletteUnit from "components/common/theme/paletteUnit"
import { Status } from "@common/types"
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
import ListboxStyled from "@common/dropdowns/listboxStyled"
import MultiColorPicker from "@common/forms/inputs/multiColorPicker"
import DatePicker from "@common/datePickers/datePicker"
import DatePickerRange from "@common/datePickers/datePickerRange"

/** 
 *
const configs = {
  'email': { field: "input", type: "email", label: 'Work Email', value: '', required: true },
  'name': { field: "input", label: 'Full Name', value: '', required: true },
  'company': { field: "input", label: 'Company', value: '', required: true  },
  'password': { field: "input", type: "password", label: 'Password', value: '', required: true, minChars: 4 }
  'message': { field: "textarea", label: 'Message', value: '', required: false, labelPosition: "left", placeholder: "Tell us about your requirements.", rows: 4, className: "textarea-nowrap" }

  'cat_id': 
  {
    field: "listbox",
      label: "Label type",
      value: "diy",
      options: {
        diy: { key: "diy", display: "DIY" },
        headphones: { key: "headphones", display: "Headphones" },
        mic: { key: "mic", display: "Mouse" },
      },
      required: true,
  },
  'name': 
  { 
    field: "input", 
    label: 'Group Name', 
    value: "Test Group Name",
    placeholder: "Enter group name.", 
    required: true,
    invisible: true, //this makes the field invisible in form.
    validate: (val) => !val && {
        isError: true,
        message: "Password must not be lesser than 4 characters",
      },
  },
  'info_for_something': 
  { 
    field: "info", // this type will automatically be removed from submit details 
    value: 'General purpose info.', 
  },
  designation: {
    field: "listbox",
    label: "Position",
    value: { key: "Product Manager", display: "Product Manager" },
    containerWidthClasses: "w-full",
    options: options,
    required: true,
    children: {   // only works for listbox
      // this will show a input box below selector if the key matched
      other:{
        rules:{
           value: "Other", 
        }
      placeholder: "Position",
      field: "input",
      maxChars: 30,
      required: true,
      label: "",
      value: "",
      }
    },
  },
  users_range: {    //RENDERS A RANGE SELECT SLIDER
    field: "range",
    min: 20,          //MIN VALUE AT LEFT END
    max: 200,         // MAX VALUE AT RIGHT END
    step: 1,          // SLIDE STEP POINT (WILL SLIDE WITH MULTIPLE OF THIS VALUE)
    label: "How many people in your team need access?", 
    value: 20,        // DEFAULT SLECTED VALUE (REQUIRED)
  },
  api_access: {       
    field: "radioList", // RENDERS A QUESTION WITH MULTIPLE CHOICES
    label: "Would you like API access?",
    label: "Would you like API access?", // QUESTION 
    options: {
      "no": {
        "display": "No Thanks"
      },
      "yes": {
        "display": "Yes Please"
      },
      "null": {
        "display": "Don't Care"
      }
      }, // OPTIONS TO SELECT FROM
    value: "No, thankyou", // DEFAULT SELECTED VALUE  (REQUIRED)
  },
  users: {
      field: "combobox", // Multiselect comboBox
      label: "Users",
      options: {key1:{key:"key", display:"display"}, ...},
      value: "", // selected keys [key1,key2,...]
      required: true,
      labelPosition: "top",
      placeholder: "Add users",
  },
}
*/

// hit Url - > handle in primary button call back
// getDetailsForSubmit -> function that allows parent to take the "details" object from formComponent and generate the final details that needs to be submitted.
export default function Form({
  formConfig,
  isActions = true, //default true, to hide action button pass isActions=false
  onChange,
  primaryAction,
  secondaryAction,
  forceActivePrimaryButton = false, // default null. if true, the main button will show up as active as opposed to waiting for completion of *required fields
  isSingleFullButton = false,
  onSecondaryActionClick,
  formHeader = "",
  formSubText = "",
  formBottomText,
  conClasses = "",
  getDetailsForSubmit,
  submitFunction,
  onSubmit,
  onSubmitResponse,
  onSubmitSuccess,
  onSubmitFail,
  enforceEdit = false, // ⚠️ boolean : WHEN TRUE, ENABLES THE "EDIT MODE" & WILL FORCE THE isFormCompleted TO CHECK IF VALUES ARE EDITED (CHECK isFormCompleted FOR DETAILS)
  /**
   * below props are used internally for recursive children layout. User of this <Form/> component can ignore these props.
   */
  parentFieldValue = null,
  isBaseForm = true,
  formHeaderAlign = "center",
  logResponse = false,
}) {
  const [configs, setConfigs] = useState(initConfig(formConfig))
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setConfigs(initConfig(formConfig))
  }, [])

  useEffect(() => {
    if (isBaseForm) setConfigs(initConfig(formConfig))
  }, [formConfig])

  useEffect(() => {
    if (onChange)
      onChange(
        configs,
        /**
         * TODO: unnecessary computation of form values, as it is not required in most cases so, we can remove these two functions from `onChange`.
         */
        getDetailsFromForm(configs),
        isFormCompleted(configs, formConfig, enforceEdit)
      ) //returns form, details, and form completion status
  }, [configs])

  if (configs == undefined) return <div></div>
  return isBaseForm ? (
    <div className={conClasses || "  "}>
      {(formHeader || formSubText) && (
        <div className="mt-4 mb-8">
          <Header variant="h6" alignment={formHeaderAlign}>
            <Header.MainHeader>{formHeader}</Header.MainHeader>
            <Header.Description>{formSubText}</Header.Description>
          </Header>
        </div>
      )}
      <form
        action="#"
        method="POST"
        className=" flex flex-col space-y-6 "
        onSubmit={(e) => handleSubmit(e)}
      >
        {Object.keys(configs)
          .filter((key) => !configs[key]?.advanced && !configs[key]?.invisible)
          .map((key) => (
            <div key={key}>{renderFormField(key, configs[key])}</div>
          ))}
        {renderAdvancedFormFields(configs)}
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : isActions == false ? null : (
          <></>
        )}

        {isActions && (
          <div className="flex flex-row-reverse pt-4">
            <Button
              disabled={
                forceActivePrimaryButton
                  ? false
                  : !isFormCompleted(configs, formConfig, enforceEdit)
              }
              variant="primary"
              width={isSingleFullButton ? "full" : "default"}
              isLoading={isLoading}
              disabledMsg={
                isLoading ? null : (
                  <ErrorMessage
                    message={`${
                      enforceEdit
                        ? "Nothing to update"
                        : "Required fields must not be empty."
                    }`}
                  />
                )
              }
            >
              {primaryAction || "Submit"}
            </Button>
            {secondaryAction && (
              <Button
                type="button"
                variant="minimal"
                onClick={() => handleSecondaryClick()}
              >
                {secondaryAction}
              </Button>
            )}
          </div>
        )}
        {formBottomText && (
          <div className="mt-5 text-sm text-primary">{formBottomText}</div>
        )}
      </form>
    </div>
  ) : (
    <div className="mt-2">
      {Object.keys(configs).map((key) => renderFormField(key, configs[key]))}
    </div>
  )

  function renderAdvancedFormFields(configs) {
    const advancedFields = Object.keys(configs)
      .filter((key) => configs[key]?.advanced && !configs[key]?.invisible)
      .map((key) => <div key={key}>{renderFormField(key, configs[key])}</div>)
    return (
      advancedFields &&
      advancedFields.length > 0 && (
        <AccordionSingle
          header={
            <div className=" normal-case font-bold text-base">Advanced Options</div>
          }
          showHeader={true}
          isOpen={false}
          expandIconPosition="left"
        >
          <div className="my-4">{advancedFields}</div>
        </AccordionSingle>
      )
    )
  }

  function renderFormField(key, details) {
    if (!details) return
    let overrideValue = ""
    if (
      details.value &&
      details.field == "textarea" &&
      Array.isArray(details.value) &&
      !details.notSplit
    )
      overrideValue = details.value.join("\n")
    else if (details.value) overrideValue = details.value

    if (
      // parentFieldValue &&
      details.rules &&
      parentFieldValue != details["rules"].value
    )
      return <></>

    if (Array.isArray(details))
      return (
        <>
          {details.map((groupConfig, index) => {
            const groupCount = details.length
            const zIndex = groupCount - index
            return (
              <div
                className="relative my-2 flex w-full items-start gap-2"
                style={{ zIndex }}
                key={index}
              >
                {Object.keys(groupConfig).map((key) => {
                  return (
                    <div className="flex-1" key={key}>
                      {renderFormField(key, {
                        ...groupConfig[key],
                        groupIndex: index,
                      })}
                    </div>
                  )
                })}
                {renderGroupActions(key, groupConfig, index, groupCount > 1)}
              </div>
            )
          })}
        </>
      )

    return isBaseForm ? (
      <div className={details.direction == "horizontal" ? "flex gap-2" : ""}>
        {renderFormFields()}
      </div>
    ) : (
      renderFormFields()
    )

    function renderFormFields() {
      return (
        <>
          {renderInput()}
          {isChildrenVisible(details, configs[key]?.value) && (
            <Form
              formConfig={details.children}
              isActions={false}
              onChange={(form) => handleChildFormChange(form, key)}
              parentFieldValue={overrideValue}
              isBaseForm={false}
            />
          )}
        </>
      )
    }

    function renderInput() {
      const { children, ...fieldProps } = details
      switch (fieldProps.field) {
        case "custom":
          return <div>{fieldProps.renderCustom()}</div>

        case "info":
          return (
            <div className="mt-4 text-gray-700 dark:text-gray-400">
              <p className={fieldProps.containerClasses}>{fieldProps.value}</p>
            </div>
          )

        case "input":
        case "multi_input":
        case "textarea":
          return (
            <Input
              {...fieldProps}
              id={key}
              label={{
                ...fieldProps.label,
                label: fieldProps.groupIndex > 0 ? "" : fieldProps.label.label,
              }}
              required={fieldProps.required}
              field={fieldProps.field || "input"}
              defaultValue={overrideValue}
              rows={fieldProps.field == "textarea" ? fieldProps.rows : null}
              fieldClasses={fieldProps.classes}
              onChange={(text, id, message) =>
                handleChange(text, id, message, details)
              }
            />
          )

        case "range":
          return (
            <Range
              value={fieldProps.value}
              min={fieldProps.min}
              max={fieldProps.max}
              step={fieldProps.step}
              label={fieldProps.label}
              required={fieldProps.required}
              id={key}
              onChange={(value) => {
                handleChange(value, key, null, details)
              }}
            />
          )

        case "radioList":
          return (
            <MultiSelect
              id={key}
              options={fieldProps.options}
              onChange={(values) => {
                handleChange(values[0], key, null, details)
              }}
              value={[fieldProps.value]}
              isSingleSelect
              label={fieldProps.label}
              required={fieldProps.required}
            />
          )
        case "multiSelect":
          return (
            <MultiSelect
              id={key}
              options={fieldProps.options}
              onChange={(values) => {
                handleChange(values, key, null, details)
              }}
              value={fieldProps.value}
              label={fieldProps.label}
              required={fieldProps.required}
            />
          )

        case "checkbox":
          return (
            <CheckboxStyled
              {...fieldProps}
              id={key}
              checked={fieldProps.value ?? false}
              disabled={fieldProps?.disabled}
              onChange={(event) =>
                handleChange(event.target.checked, key, null, details)
              }
              label={fieldProps.label}
              required={fieldProps.required}
            />
          )

        case "listbox":
          return (
            <ListboxStyled
              {...fieldProps}
              defaultSelectedKey={fieldProps.value ? fieldProps.value : undefined}
              label={{
                ...fieldProps.label,
                label: fieldProps.groupIndex > 0 ? "" : fieldProps.label.label,
              }}
              id={key}
              required={fieldProps.required}
              onSelect={(selectedKey) =>
                handleChange(selectedKey, key, null, details)
              }
              placeHolderText={fieldProps.placeholder}
            />
          )
        case "combobox":
          return (
            <ComboboxStyled
              {...fieldProps}
              onSelect={(selectedKeys) =>
                handleChange(selectedKeys, key, null, details)
              }
              placeHolderText={fieldProps.placeholder}
              defaultSelectedKeys={fieldProps.value}
              required={fieldProps.required}
              label={fieldProps.label}
              id={key}
            />
          )

        case "recaptcha":
          return (
            fieldProps.siteKey && (
              <div className=" w-full py-2 flex items-center justify-center">
                <ReCAPTCHA
                  sitekey={fieldProps.siteKey}
                  theme={fieldProps.theme}
                  onChange={(token) => handleChange(token, key, null, details)}
                />
              </div>
            )
          )

        case "color_picker":
          return (
            <PaletteUnit
              label={fieldProps.label}
              colorCode={fieldProps.value}
              onColorChange={(pickedColor) =>
                handleChange(pickedColor, key, null, details)
              }
              required={fieldProps.required}
              id={key}
            />
          )

        case "multi_color_picker":
          return (
            <MultiColorPicker
              label={fieldProps.label}
              onChange={(pickedColors) =>
                handleChange(pickedColors, key, null, details)
              }
              colors={fieldProps.value}
              colorsCount={fieldProps.valueCount}
              required={fieldProps.required}
              id={key}
            />
          )
        case "date_picker":
          return (
            <DatePicker
              label={fieldProps.label}
              onChange={(date) => handleChange(date, key, null, details)}
              date={fieldProps.value}
              placeHolder={fieldProps.placeholder}
              required={fieldProps.required}
              id={key}
            />
          )
        case "date_picker_range":
          return (
            <DatePickerRange
              label={fieldProps.label}
              onChange={(dateRange) => handleChange(dateRange, key, null, details)}
              range={fieldProps.value}
              required={fieldProps.required}
              id={key}
            />
          )
      }
    }
  }

  function renderGroupActions(key, groupConfig, groupIndex, isRemovable) {
    // TODO - mt-[35px]. Center geometry.
    return (
      <div
        className={`flex items-center gap-2 my-auto h-full  ${
          groupIndex == 0 ? "mt-[35px]" : ""
        }`}
      >
        <Button
          type="button"
          variant="minimal"
          size="icon"
          onClick={() => {
            handleGroupActionClick(key, groupConfig, groupIndex, "add")
          }}
        >
          <Svg name="plus" classes="w-5 h-5 text-gray-600 dark:text-gray-300" />
        </Button>
        <Button
          type="button"
          size="icon"
          onClick={() => {
            handleGroupActionClick(key, groupConfig, groupIndex, "remove")
          }}
          disabled={!isRemovable}
          variant="minimal"
        >
          <Svg
            name="plus"
            classes="w-5 h-5 rotate-45 text-gray-600 dark:text-gray-300"
          />
        </Button>
      </div>
    )
  }

  function handleGroupActionClick(key, groupConfig, groupIndex, actionType) {
    let groups = configs[key]
    const newGroupConfig = formConfig[key]
      ? deepCopyObject(formConfig[key][0])
      : groupConfig
    if (actionType == "add") {
      groups.splice(groupIndex + 1, 0, newGroupConfig)
    } else if (actionType == "remove") {
      const predicate = (_, index) => index != groupIndex
      groups = groups.filter(predicate)
    }
    setConfigs({ ...configs, [key]: groups })
  }

  function handleChildFormChange(childForm, key) {
    const tempForm = { ...configs }
    tempForm[key] = { ...tempForm[key], children: childForm }
    setConfigs(tempForm)
  }

  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation() // for cases such as form within a form ex: Login > Forgot Password
    setErrorMessage(null)
    if (isFormCompleted(configs, formConfig, enforceEdit) && isFormValid(configs)) {
      setErrorMessage(null)
      setIsLoading(true)
      submit()
    } else {
      setErrorMessage(ErrorCodes.MULTI)
    }
  }

  function isFormValid(form) {
    const errorMessages = {}
    for (const key in form) {
      if (Object.hasOwnProperty.call(form, key)) {
        const field = form[key]
        const { validate, value } = field
        if (validate && validate instanceof Function) {
          const _value = typeof value == "string" ? value : value.key
          const { isError, message } = validate(_value)
          if (isError) {
            errorMessages[key] = message
          }
        }
      }
    }
    return Object.keys(errorMessages).length == 0
  }

  function handleChange(value, id, errorMessage, config) {
    if (config.field == "textarea" && !config.notSplit) {
      value = value.split("\n")?.filter(Boolean)
    }
    const tempForm = { ...configs }
    if (config.group_id) {
      tempForm[config.group_id][config.groupIndex][id] = {
        ...tempForm[config.group_id][config.groupIndex][id],
        value,
        errorMessage,
      }
    } else {
      tempForm[id] = { ...tempForm[id], value, errorMessage }
    }
    setConfigs(tempForm)
  }

  async function submit() {
    let details = getDetailsFromForm(configs)
    if (
      details.password &&
      details.confirm_password &&
      details.password !== details.confirm_password
    ) {
      setErrorMessage("Those passwords didn't match. Please try again.")
      setIsLoading(false)
      return
    }
    if (getDetailsForSubmit) details = getDetailsForSubmit(details)
    if (onSubmit) {
      onSubmit(details)
    }

    if (!submitFunction) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
      if (onSubmitSuccess) onSubmitSuccess()
      return
    }

    const { data, status, message } = (await submitFunction(details)) || {}
    setIsLoading(false)
    if (onSubmitResponse) onSubmitResponse(data)
    if (status === Status.Success) {
      if (onSubmitSuccess) onSubmitSuccess(data)
      setErrorMessage(null)
    }
    if (status === Status.Failure) {
      setErrorMessage(message)
      if (onSubmitFail) onSubmitFail(message, data)
    }
    if (logResponse) submitGoogleForm(formHeader, details)
  }

  function handleSecondaryClick() {
    if (onSecondaryActionClick) onSecondaryActionClick()
  }

  function isChildrenVisible(details, value) {
    return !!details["children"] && !!value
  }

  function initConfig(formConfig) {
    return deepCopyObject(formConfig)
  }
}

function submitGoogleForm(formHeader, details) {
  const url = `https://docs.google.com/forms/d/e/1FAIpQLSdL-I4gmEMAqypUqXg8QcemCNEmkE7eZ8N0IhVLhW3S8dUQXw/formResponse?usp=pp_url&entry.1395913944=${
    window.location.href
  }&entry.851483779=${formHeader}&entry.1923022617=${JSON.stringify(
    details
  )}&submit=Submit`
  hitUrl(url)
}
