import { joinClassNames } from "@common/lib/util"

export default function StickyHeaderFooter(props) {
  return (
    <div className={joinClassNames("h-full w-full flex flex-col", props.className)}>
      {props.children}
    </div>
  )
}

// function useStickyHeaderFooterContext() {
//   const context = useContext(StickyHeaderFooterContext)
//   if (!context) {
//     throw new Error(
//       `Toggle compound components cannot be rendered outside the Toggle component`
//     )
//   }
//   return context
// }

export function Header({ children, className = "" }) {
  // const { test } = useStickyHeaderFooterContext()
  return <header className={className}>{children}</header>
}

export function Main({ children, className, styles }) {
  // const { test } = useStickyHeaderFooterContext()
  return (
    <div
      className={joinClassNames("overflow-y-auto flex-grow ", className)}
      style={styles}
    >
      {children}
    </div>
  )
}

export function Footer({ children, className }) {
  return <footer className={className}>{children}</footer>
}
