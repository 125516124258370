// React imports
import { useEffect, useState } from "react"

// Component and utility imports
import { getDateDigitsFromDateObj, getDateObjFromDateDigits, getDateWithSeparator } from "@common/datePickers/utils"
import {

  joinClassNames,
} from "@common/lib/util"
import { Popover } from "@common/popover"
import { Calendar } from "react-date-range"
import { DatePickerProps } from "@common/datePickers/types"
import Label from "@common/forms/label"
import Svg from "@common/svg"

export default function DatePicker({
  date,
  placeHolder = "YYYY-MM-DD",
  onChange,
  label = {},
  required,
  id,
}: DatePickerProps) {
  // State for selected date
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    date ? getDateObjFromDateDigits(date) : null
  )
  const [isError, setIsError] = useState<boolean>(false)

  // Effect to update state when date prop changes
  useEffect(() => {
    if (date) setSelectedDate(getDateObjFromDateDigits(date))
  }, [date])

  // Handles date selection from calendar
  const handleDateSelect = (date: Date) => {
    setSelectedDate(date)
    onChange(getDateDigitsFromDateObj(date))
    setIsError(false)
  }

  // Render component
  return (
    <Label
      {...label}
      handleError={isError}
      error="This field is required"
      required={required}
      id={id}
    >
      <Popover
        isClickType
        position="bottom"
        onVisibleChange={(visibility) => {
          if (!visibility && required && !selectedDate) setIsError(true)
        }}
      >
        <Popover.Button>
          <div
            className={joinClassNames(
              "border rounded-md py-2 w-36 text-center flex justify-center items-center gap-2 text-sm ",
              selectedDate ? " text-primary" : " text-muted-foreground"
            )}
          >
            <Svg name="calendar" classes="w-4" />
            {selectedDate ? (
              <span key={selectedDate.toString()} className="animate-loadMildFadeUp">
                {getDateWithSeparator(getDateDigitsFromDateObj(selectedDate))}
              </span>
            ) : (
              placeHolder
            )}
          </div>
        </Popover.Button>
        <Popover.Body>
          <div className="p-2 flex justify-center items-center animate-slideDown">
            <Calendar
              date={selectedDate || new Date()}
              onChange={handleDateSelect}
            />
          </div>
        </Popover.Body>
      </Popover>
    </Label>
  )
}
