/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from "react"

// Third-party library imports
import { cva, type VariantProps } from "class-variance-authority"
import { Popover, PopoverProps } from "@common/popover"

// Local file imports
import { joinClassNames } from "../lib/util"
import Tag, { TagProps } from "@common/tags/tag"

// Variants definition
const tagsVariants = cva(
  "flex shrink min-w-0 items-center justify-start overflow-hidden pr-1 gap-1",
  {
    variants: {
      mode: {
        shrink: "shrink",
        wrap: "flex-wrap",
      },
    },
    defaultVariants: {
      mode: "wrap",
    },
  }
)

// Props interface
export type TagsProps = VariantProps<typeof tagsVariants> & {
  children?: React.ReactNode | React.ReactNode[]
  maxShowTagLimit?: number
  tagColor?: TagProps["color"]
  tagSize?: TagProps["size"]
  overflowBoxClasses?: string
} & Pick<PopoverProps, "isClickType" | "position">

// Main component
export default function Tags({
  children,
  maxShowTagLimit,
  tagColor,
  tagSize,
  overflowBoxClasses,
  isClickType,
  position,
  ...props
}: TagsProps) {
  const filteredChildren = React.Children.toArray(children).filter(Boolean)
  const _maxTagLimit = maxShowTagLimit ?? filteredChildren.length
  const tagsOverMaxLimit = filteredChildren.length - _maxTagLimit

  return (
    <div className={joinClassNames(tagsVariants(props))}>
      {filteredChildren.slice(0, _maxTagLimit)}
      {tagsOverMaxLimit > 0 && (
        <Popover isClickType={isClickType} position={position}>
          <Popover.Button>
            <Tag color={tagColor} size={tagSize}>
              <div className={overflowBoxClasses}>{`+${tagsOverMaxLimit} More`}</div>
            </Tag>
          </Popover.Button>
          <Popover.Body>
            <div className="p-1 min-w-0 max-w-4xl">
              <Tags>{filteredChildren.slice(_maxTagLimit).map((tag) => tag)}</Tags>
            </div>
          </Popover.Body>
        </Popover>
      )}
    </div>
  )
}
