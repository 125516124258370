import React from "react"
import Svg from "../svg"
import Accordion from "../accordion/accordion"
import { Header } from "@common/header/headerUnit"

export default function AccordionSingle({
  children,
  iconName = "arrow-right-short",
  isOpen = true,
  header,
  showHeaderOnClose = true,
  expandIconPosition = "right", // "left"
  unmountOnClose = false,
}) {
  return (
    <Accordion
      defaultSelectedIndexes={isOpen ? [0] : null}
      unmountOnClose={unmountOnClose}
    >
      <div key={0}>
        <Accordion.Header index={0}>
          {({ isSelected, select }) => (
            <button
              onClick={() => select(0)}
              className="w-full select-none"
              type="button"
            >
              {renderHeader(isSelected)}
            </button>
          )}
        </Accordion.Header>
        <Accordion.Body index={0}>
          {() => <div className="animate-slideDown">{children}</div>}
        </Accordion.Body>
      </div>
    </Accordion>
  )

  function renderHeader(isSelected) {
    return (
      <div
        className={
          "w-full flex items-center cursor-pointer " +
          (expandIconPosition === "left" ? "justify-start" : "justify-between")
        }
      >
        {expandIconPosition === "left" && renderIcon(isSelected)}
        {isSelected && !showHeaderOnClose ? (
          <div></div>
        ) : (
          <Header.MainHeader>{header}</Header.MainHeader>
        )}
        {expandIconPosition === "right" && renderIcon(isSelected)}
      </div>
    )
  }

  function renderIcon(isSelected) {
    return (
      <Svg
        name={iconName}
        classes={
          "h-5 w-5 text-gray-800 dark:text-gray-300 transform mr-1  duration-150 ease-in-out " +
          (isSelected ? " rotate-90 " : " rotate-0 ")
        }
      />
    )
  }
}
