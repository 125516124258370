import Label from "@common/forms/label"
import { isNullOrEmpty } from "@common/lib/util"
import DatePicker from "@common/datePickers/datePicker"
import { DatePickerRangeProps, Range } from "@common/datePickers/types"
import { useEffect, useState } from "react"

export default function DatePickerRange({
  label={},
  range,
  onChange,
  required,
  id,
}: DatePickerRangeProps) {
  // State for selected range
  const [selectedRange, setSelectedRange] = useState<Range | undefined>(range)

  // Handles range selection from DatePicker
  useEffect(() => {
    if (!isNullOrEmpty(range)) setSelectedRange(range)
  }, [range])

  function handleRangeChange(date: string, index: number) {
    setSelectedRange((prevRange) => {
      const updatedRange = [...(prevRange || [])] as Range
      updatedRange[index] = date
      onChange(updatedRange)
      return updatedRange
    })
  }

  return (
    <Label {...label} required={required} id={id}>
      <div className="border rounded-md p-4 flex justify-between w-full ">
        <DatePicker
          label={{label: "From",font: "normal",}}
          onChange={(fromDate) => handleRangeChange(fromDate, 0)}
          date={selectedRange?.[0]}
          required={required}
          id={id}
        />
        {/* connecting line */}
        <div className=" w-full border-t mt-12" />
        <DatePicker
          label={{label: "To",font: "normal",}}
          onChange={(toDate) => handleRangeChange(toDate, 1)}
          date={selectedRange?.[1]}
          required={required}
          id={id}
        />
      </div>
    </Label>
  )
}
