export default function Text({ children, className }) {
  return (
    <div
      className={
        "font-normal text-sm leading-5 text-gray-700 dark:text-gray-400 " +
        (className ? className : null)
      }
    >
      {children}
    </div>
  )
}
