import React, { useState, useRef } from "react"
import Label, { LabelProps } from "@common/forms/label"
import { Popover } from "@common/popover"
import { HexColorPicker } from "react-colorful"
import Input from "@common/forms/input"
import Button from "@common/buttons/button"

type PaletteUnitProps = Pick<LabelProps, "required" | "id"> & {
  colorCode: string 
  onColorChange: (color: string) => void 
  label?: LabelProps 
}
export default function PaletteUnit({
  colorCode,
  onColorChange,
  label = {},
  required,
  id,
}: PaletteUnitProps) {
  const [pickedColor, setPickedColor] = useState<string>(colorCode) //🚦 HOLDS NEWLY SELECTED COLOR VALUE
  const isColorChanged = !(pickedColor == colorCode) && pickedColor
  const colorPickerRef = useRef<any>(null)

  function handleClick() {
    if (isColorChanged) {
      onColorChange(pickedColor)
      closeColorPicker()
    }
  }

  function closeColorPicker() {
    colorPickerRef?.current?.click()
  }

  return (
    <Label {...label} required={required} id={id}>
      <div className="flex gap-2">
        {renderColorPicker()}
        <p key={colorCode} className="text-secondary text-sm flex relative my-auto animate-loadMildFadeUp">{colorCode || "None"}</p>
      </div>
    </Label>
  )

  function renderColorPicker() {
    return (
      <Popover>
        <Popover.Button>
          <div
            ref={colorPickerRef}
            style={{
              backgroundColor: colorCode,
            }}
            className="w-8 h-8 rounded-full cursor-pointer border transform hover:scale-105 "
          />
        </Popover.Button>
        <Popover.Body>{renderColorInput()}</Popover.Body>
      </Popover>
    )
  }

  function renderColorInput() {
    return (
      <div className="p-2 w-52 space-y-4">
        <div className="flex justify-center ">
          <HexColorPicker color={pickedColor} onChange={setPickedColor} />
        </div>
        {/* @ts-ignore */}
        <Input
          onChange={(text: string) => setPickedColor(text)}
          defaultValue={pickedColor}
          placeholder="#ffffff"
          required={required}
          id={id}
        />
        <div className="flex justify-end gap-2">
          <Button
            onClick={() => {
              isColorChanged ? setPickedColor(colorCode) : closeColorPicker()
            }}
            size={"sm"}
            variant={"minimal"}
          >
            {isColorChanged ? "Reset" : "Cancel"}
          </Button>
          <Button
            variant={"primary"}
            onClick={handleClick}
            disabled={!isColorChanged}
            size={"sm"}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }
}
