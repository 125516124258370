import { joinClassNames } from "@common/lib/util"
import React, { createContext, useEffect, useState } from "react"
const AccContext = createContext()

export const Accordion = ({
  children,
  defaultSelectedIndexes = null,
  onlyOneOpen = true,
  unmountOnClose = false,
}) => {
  const [selectedIndexes, setSelectedIndexes] = useState(
    defaultSelectedIndexes || []
  )

  useEffect(() => {
    if (defaultSelectedIndexes) setSelectedIndexes(defaultSelectedIndexes)
  }, [defaultSelectedIndexes])

  return (
    <AccContext.Provider
      value={{ selectedIndexes, setSelectedIndexes, onlyOneOpen, unmountOnClose }}
    >
      {children}
    </AccContext.Provider>
  )
}
const AccordionHeader = ({ children, index }) => (
  <AccContext.Consumer>
    {({ selectedIndexes, setSelectedIndexes, onlyOneOpen }) =>
      children({
        isSelected: selectedIndexes.includes(index),
        select: (index) =>
          setSelectedIndexes(() => {
            if (selectedIndexes.includes(index)) {
              const filteredIndexes = selectedIndexes.filter(
                (item) => item !== index
              )
              return filteredIndexes
            } else {
              if (onlyOneOpen) {
                return [index]
              }
              return [...selectedIndexes, index]
            }
          }),
      })
    }
  </AccContext.Consumer>
)
const AccordionBody = ({ children, index }) => (
  <AccContext.Consumer>
    {({ selectedIndexes, unmountOnClose }) => {
      const isSelected = selectedIndexes.includes(index)
      if (unmountOnClose) return isSelected ? children(isSelected) : <></>
      return (
        <div className={joinClassNames(isSelected ? "block" : "hidden")}>
          {children(isSelected)}
        </div>
      )
    }}
  </AccContext.Consumer>
)

Accordion.Header = AccordionHeader
Accordion.Body = AccordionBody
export default Accordion
