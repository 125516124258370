/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import Label from "@common/forms/label"
import Loader from "@common/loader"
import { ListBoxProps, sectionException } from "@common/types"
import { Listbox } from "@headlessui/react"
import { isEmpty, isNullOrEmpty, joinClassNames } from "components/common/lib/util"
import SearchBar from "components/common/searchBar"
import { Fragment, useEffect, useState } from "react"
import EmptyList from "./emptyList"
import ListBoxOption, { ListboxOptionContent } from "./listOption"
import ListSection from "./listSection"
import { getListedOptionClasses, useDropdown } from "@common/hooks/useDropdown"
import { useInView } from "react-intersection-observer"
import Svg from "@common/svg"

export default function ListboxStyled({
  placeHolderText = "Select",
  options,
  onSelect,
  defaultSelectedKey,
  label = {},
  required = false,
  disabled = false,
  sortOptions = false,
  enableSearch = false,
  showSingleItem = false,
  isLoading = false,
  buttonWidthClasses = "w-full",
  optionWidthClasses = "w-full",
  enableReset = false,
  resetText = "clear selection",
  id,
}: ListBoxProps) {
  const {
    groupedOptions,
    selectedKeys,
    optionsArray,
    handleInputChange,
    setSelectedKeys,
  } = useDropdown(
    options,
    sortOptions,
    defaultSelectedKey ? [defaultSelectedKey] : []
  )

  useEffect(() => {
    if (defaultSelectedKey) setSelectedKeys([defaultSelectedKey])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedKey])

  const selectedKey = isNullOrEmpty(selectedKeys) ? null : selectedKeys[0]
  const [showError, setShowError] = useState<boolean>(false)
  const { ref, inView: areOptionsVisible } = useInView() //dont show error when options are visible (handled in <ComboboxStyled /> by onfocus)

  const buttonClasses = joinClassNames(
    "list-button disabled:cursor-not-allowed disabled:opacity-50 transition-colors delay-75",
    !areOptionsVisible && showError && "border-destructive",
    buttonWidthClasses
  )
  const listOptionsClasses = joinClassNames(
    optionWidthClasses,
    "list-option",
    "absolute"
  )

  const handleBlur = () => {
    if (required && !selectedKey) {
      setShowError(true)
    }
  }

  function handleChange(selectedKey: string) {
    setShowError(false)
    setSelectedKeys([selectedKey])
    if (onSelect) onSelect(selectedKey)
  }

  const selectedOption = optionsArray.filter((option) => option.key == selectedKey)

  const optionsLength = Object.keys(options || {}).length
  const showSearchBar = enableSearch && optionsLength > 1
  if (!showSingleItem && optionsLength == 1) return <></>

  return (
    <Label
      {...label}
      id={id}
      required={required}
      error={`${label?.label || "This field"} is requied.`}
      handleError={!areOptionsVisible && showError}
    >
      <Listbox disabled={disabled} value={selectedKey} onChange={handleChange}>
        <div className="relative" tabIndex={0} onBlur={handleBlur}>
          {isLoading ? (
            <div className={buttonClasses}>
              <Loader />
            </div>
          ) : (
            <Listbox.Button
              onClick={() => handleInputChange("")}
              className={buttonClasses}
            >
              {!isEmpty(selectedOption) ? (
                <div
                  className={joinClassNames(
                    optionsLength !== 1 ? "w-[calc(100%_-_20px)]" : "w-full"
                  )}
                >
                  <ListBoxOption option={selectedOption[0]} isHoverable={false} />
                </div>
              ) : (
                <div className="px-2 py-2 text-muted-foreground bg-transparent">
                  {placeHolderText}
                </div>
              )}
              {/* ptionsLength == 1 👉🏻 makes sure no toggle for one option */}
              {optionsLength == 1 ? null : (
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <Svg
                    name="arrow-up-down"
                    classes="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              )}
            </Listbox.Button>
          )}
          <Listbox.Options ref={ref} className={listOptionsClasses}>
            {(showSearchBar || (enableReset && selectedKey)) && (
              <div className="sticky top-0 z-50 m-2 space-y-1 text-center backdrop-blur-sm ">
                {showSearchBar ? (
                  <div className="rounded-md border bg-background px-2 ">
                    <SearchBar
                      onChange={handleInputChange}
                      onSubmit={undefined}
                      renderHelpContent={undefined}
                      value={undefined}
                      placeholder={undefined}
                    />
                  </div>
                ) : null}

                {enableReset && selectedKey ? (
                  <button
                    onClick={() => {
                      if (onSelect) onSelect(null)
                      setSelectedKeys([])
                      handleInputChange("")
                    }}
                    className={
                      "text-secondary border rounded-md w-full px-2 py-1 text-xs disabled:opacity-50"
                    }
                  >
                    {resetText}
                  </button>
                ) : null}
              </div>
            )}

            <div className="relative">
              {isNullOrEmpty(groupedOptions) ? (
                <EmptyList />
              ) : (
                Object.entries(groupedOptions).map(([section, options]) => (
                  <Fragment key={section}>
                    {section !== sectionException.other && (
                      <ListSection sectionName={section} />
                    )}
                    {options?.map((option) => {
                      return (
                        <Listbox.Option
                          key={option.key}
                          value={option.key}
                          disabled={option.disabled}
                          className={({ active }) =>
                            getListedOptionClasses(
                              active,
                              option.disabled || disabled
                            )
                          }
                        >
                          {({ selected }) => (
                            <ListboxOptionContent
                              icon={option.icon}
                              display={option.display}
                              selected={selected}
                              showSelectedIcon={true}
                            />
                          )}
                        </Listbox.Option>
                      )
                    })}
                  </Fragment>
                ))
              )}
            </div>
          </Listbox.Options>
        </div>
      </Listbox>
    </Label>
  )
}
