import { useEffect, useState } from "react"

// Components
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
import RadioStyled from "@common/forms/inputs/radioStyled"
import Label from "@common/forms/label"
import ListSearch from "@common/listSearch"

// Utility Functions
import { isArray, isEmpty, isNullOrEmpty } from "@common/lib/util"

// Types for type checking
import { Options } from "@common/types"
import { MultiSelectValueProps, MultiSelectProps } from "@common/forms/inputs/types"

export default function MultiSelect({
  options,
  onChange,
  isSingleSelect,
  allowSearchAfter,
  value,
  label = {},
  required,
  id,
}: MultiSelectProps) {
  const [isError, setIsError] = useState<boolean>(false)
  const [selectionState, setSelectionState] = useState<MultiSelectValueProps>(
    value || []
  )

  useEffect(() => {
    setSelectionState(value || [])
  }, [value])

  const handleChange = (optionKey: string, isSelected?: boolean | null) => {
    let state = [...selectionState]
    const index = state.indexOf(optionKey)

    if (isSingleSelect) {
      if (state.includes(optionKey)) state = []
      else state = [optionKey]
    } else {
      if (isSelected && index === -1) state.push(optionKey)
      else state.splice(index, 1)
    }
    setSelectionState(state)
    onChange?.(state)
    if (isEmpty(state) && required) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }

  const transformOptionsForListSearch = (options: Options) => {
    const transformed = {} as any
    for (const key in options) {
      transformed[key] =
        typeof options[key].display === "string" ? options[key].display : key
    }
    return transformed
  }

  return (
    <Label
      {...label}
      handleError={isError}
      error="This field is required"
      required={required}
      id={id}
    >
      <div className="bg-background border rounded-md max-h-60 overflow-auto p-2 space-y-2">
        {allowSearchAfter && Object.keys(options).length > allowSearchAfter ? (
          <ListSearch
            list={transformOptionsForListSearch(options)}
            initSelectedKeys={selectionState}
            multiSelectList={false}
            onMultiSelectList={(selectedKeys: MultiSelectValueProps) =>
              onChange?.(selectedKeys)
            }
            renderListUnit={(optionKey) => {
              return (
                <div key={optionKey} className="pt-4">
                  {isSingleSelect
                    ? renderRadioStyled(optionKey)
                    : renderCheckboxStyled(optionKey)}
                </div>
              )
            }}
          />
        ) : (
          <div className="space-y-4">
            {Object.keys(options).map((optionKey) =>
              isSingleSelect
                ? renderRadioStyled(optionKey)
                : renderCheckboxStyled(optionKey)
            )}
          </div>
        )}
      </div>
    </Label>
  )

  function renderRadioStyled(optionKey: string) {
    if (options[optionKey])
      return (
        <RadioStyled
          key={optionKey}
          checked={!isNullOrEmpty(selectionState) && selectionState[0] == optionKey}
          id={label?.label + optionKey}
          onChange={() => handleChange(optionKey)}
          label={{
            label: options[optionKey].display,
            labelPosition: "right",
            font: "normal",
          }}
        />
      )
  }

  function renderCheckboxStyled(optionKey: string) {
    if (options[optionKey])
      return (
        <CheckboxStyled
          key={optionKey}
          checked={isArray(selectionState) && selectionState.includes(optionKey)}
          id={label?.label + optionKey}
          onChange={(event: any) => handleChange(optionKey, event.target.checked)}
          label={{
            label: options[optionKey].display,
            labelPosition: "right",
            font: "normal",
          }}
        />
      )
  }
}
