import { useState, useEffect, useRef } from "react"
import Tag from "@common/tags/tag"
import Label from "@common/forms/label"
import { joinClassNames } from "@common/lib/util"

// id = compulsory
// field = input (default), textarea
// type = email, password
// labelPosition: top (default), inline (overrides placeholder), left
// (TODO) autoFocus -> boolean. false by default
// fieldClasses -> use this to add incremental classes
// fieldStyles -> use this to override styles
/**
 *
 *  defaultValue -> if defaultValue is an array then we will set multi_input values otherwise `text`
 *
 */
export default function Input({
  field = "input",
  label = {},
  placeholder,
  defaultValue,
  id,
  type,
  required,
  minChars,
  maxChars,
  onChange,
  autoComplete,
  rows,
  fieldClasses,
  fieldStyles,
  shouldHandleLabelActionError,
  min,
  pattern,
  disabled,
}) {
  const [errorMessage, setErrorMessage] = useState(null)
  const [text, setText] = useState(defaultValue || "")
  const [showError, setShowError] = useState(false)
  const [multiInputValues, setMultiInputValues] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    if (defaultValue != null) {
      if (Array.isArray(defaultValue)) {
        setText("")
        // checking if it's the first render, on `onChange` this component is rerendering again so we don't want to set the values if it's consecutive render.
        if (multiInputValues.length == 0) {
          const values = defaultValue.map((value) => ({
            value,
            isValid: !checkError(value),
          }))
          setMultiInputValues(values)
        }
      } else setText(defaultValue)
    }
  }, [defaultValue])

  const isError = showError && errorMessage && errorMessage.length > 0
  const isMultiInputError =
    multiInputValues.flat().length == 0
      ? false
      : multiInputValues.flat().some((value) => !value.isValid)

  const handleKeyDown = (e) => {
    const value = text.trim()
    if (e.key === "Backspace" && !text) {
      handleRemove(multiInputValues.at(-1))
    }
    const isKeyMatched = ["Tab", "Enter", ","].includes(e.key)

    if (value && isKeyMatched) {
      e.preventDefault()
      handleMultiInputValue(value)
    }
  }

  const handleRemove = (tag) => {
    const newValues = multiInputValues.filter((obj) => obj.value !== tag.value)
    setMultiInputValues(newValues)
    if (onChange) {
      onChange(
        newValues?.map((obj) => obj.value),
        id
      )
    }
  }

  const isEmpty =
    multiInputValues.length == 0 ||
    (multiInputValues.some((_multiInputValues) =>
      Array.isArray(_multiInputValues)
    ) &&
      multiInputValues[0].length == 0)

  return (
    <Label
      {...label}
      required={required}
      handleError={shouldHandleLabelActionError || isError}
      error={isError ? errorMessage : null}
      id={id}
    >
      {renderField()}
    </Label>
  )

  function handleMultiInputFocus() {
    inputRef.current.focus()
    handleOnFocus()
  }
  function handleOnFocus() {
    setErrorMessage(null)
    setShowError(false)
  }

  function handleMultiInputValue(value) {
    const message = checkError(value)
    const isDuplicate = multiInputValues.some((v) => v.value == value && value)

    if (isDuplicate) {
      setShowError(true)
      setErrorMessage("Duplicate values are not allowed")
    } else if (message) {
      setShowError(true)
    } else if (value && !isDuplicate) {
      const newValues = [...multiInputValues, { value, isValid: !message }]
      setMultiInputValues(newValues)
      setText("")

      if (onChange) {
        onChange(
          newValues.map((obj) => obj.value),
          id,
          message
        )
      }
    }
  }

  function handleOnBlur(isTag) {
    if (field === "multi_input" && text) {
      handleMultiInputValue(text.trim())
    } else {
      checkError(text, isTag)
      setShowError(true)
    }
  }

  function handleChange(input, isMultiInput) {
    const value = type === "number" && !isNaN(input) ? Number(input) : input
    setText(value)
    if (!isMultiInput) {
      if (onChange) onChange(value, id, checkError(value))
    }
  }

  function checkError(input, isTag) {
    let message = ""
    const isFieldRequired = required == true
    const inputLength = input.length
    if (isTag && isFieldRequired && multiInputValues.length == 0) {
      message = `${getLabelName()} is required.`
    } else {
      if (isFieldRequired && inputLength == 0 && field != "multi_input")
        message = `${getLabelName()} is required.`
      if (minChars && inputLength < minChars)
        message = `${getLabelName()} should have minimum ${minChars} characters.`
      if (maxChars && inputLength > maxChars) {
        message = `${getLabelName()} should not have more than ${maxChars} characters.`
      }
      if (min !== undefined && Number(input) < 0) {
        message = `${getLabelName()} should not have less than ${min}.`
      }
      if (type == "email" && (input.indexOf("@") == -1 || input.indexOf(".") == -1))
        message = "Please enter a valid email."
      if (pattern) {
        const regExp = new RegExp(pattern, "i")
        if (!input || !regExp.test(input?.trim())) message = `Enter a valid ${label?.label}`
      }
    }
    setErrorMessage(message)
    return message
  }

  function getLabelName() {
    return label?.label || "This field"
  }

  function renderField() {
    // TODO: border border-border find root cause
    const classes = joinClassNames(
      "block w-full px-3 py-[6px] text-foreground border border-border focus:border-primary focus:ring-0 rounded-md placeholder:text-muted-foreground bg-transparent disabled:bg-slate-50 dark:disabled:bg-zinc-900 disabled:cursor-not-allowed disabled:opacity-50",
      fieldClasses,
      isError && "border-destructive"
    )

    // const holder =
    //   labelPosition == "inline"
    //     ? label + (required == true ? " *" : "")
    //     : placeholder

    if (field == "multi_input") {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={joinClassNames(
            classes,
            isMultiInputError ? " border-destructive" : "",
            " py-0 px-0"
          )}
          onClick={handleMultiInputFocus}
        >
          {renderTags()}
        </div>
      )
    }
    if (field == "textarea")
      return (
        <textarea
          value={text}
          rows={rows || "4"}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={() => handleOnFocus()}
          onBlur={() => handleOnBlur()}
          id={id}
          name={label?.label}
          type={type}
          autoComplete={autoComplete || type}
          required={required}
          placeholder={placeholder}
          className={classes}
          style={fieldStyles}
        />
        /* {text}
        </textarea> */
      )

    return (
      <input
        value={text}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => handleOnFocus()}
        onBlur={() => handleOnBlur()}
        id={id || type}
        name={label?.label}
        type={type}
        autoComplete={autoComplete || type}
        required={required}
        placeholder={placeholder}
        className={classes}
        style={fieldStyles}
        pattern={pattern}
        min={min}
        disabled={disabled}
      />
    )
  }
  function renderTags() {
    return (
      <ul className="flex flex-wrap items-center w-full shrink-0 min-w-0 h-full gap-x-2 gap-y-2 ">
        {multiInputValues.map((tag) => (
          <li key={tag.value}>
            <Tag
              color={!tag.isValid ? "red" : "transparent"}
              cross
              onCrossClick={() => handleRemove(tag)}
            >
              {tag?.value}
            </Tag>
          </li>
        ))}
        <li>
          <input
            ref={inputRef}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            value={text}
            onChange={(e) => handleChange(e.target.value, true)}
            onKeyDown={handleKeyDown}
            onBlur={() => handleOnBlur(true)}
            className="shrink focus:outline-none bg-transparent min-w-0 max-w-[12rem] focus:border-0 focus:ring-0 border-0 h-full py-0 px-0"
            placeholder={isEmpty ? placeholder : ""}
            id={id || type}
            name={label?.label}
            type={type}
            autoComplete={autoComplete || type}
            style={fieldStyles}
            pattern={pattern}
          />
        </li>
      </ul>
    )
  }
}
