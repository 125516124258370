// React imports
import { useEffect, useRef, useState } from "react"

// Headless UI and Heroicons
import { Combobox } from "@headlessui/react"

// Common components
import Label from "@common/forms/label"
import EmptyList from "@common/dropdowns/emptyList"
import ListBoxOption, { ListboxOptionContent } from "@common/dropdowns/listOption"
import ListSection from "@common/dropdowns/listSection"
import Loader from "@common/loader"
import OptionForm from "@common/optionCreate"
import Tag from "@common/tags/tag"
import Tags from "@common/tags/tags"
import Svg from "@common/svg"

// Hooks and utilities
import { getListedOptionClasses, useDropdown } from "@common/hooks/useDropdown"
import { isNullOrEmpty, joinClassNames } from "@common/lib/util"

// Types
import { ListBoxProps, sectionException } from "@common/types"

type ComboboxStyledProps = ListBoxProps & {
  handleOptionCreate?: (value: string) => void
  singleSelectMode?: boolean
  sortOptions?: boolean
  maxSelectCount?: number
  minSelectCount?: number
  enableOptionCreate?: boolean
  defaultSelectedKeys?: any[]
}

export default function ComboboxStyled({
  placeHolderText = "search or select",
  options,
  onSelect,
  defaultSelectedKeys = [],
  required = false,
  disabled = false,
  singleSelectMode = false,
  sortOptions = false,
  maxSelectCount,
  minSelectCount,
  handleOptionCreate,
  enableOptionCreate,
  label = {},
  id,
}: ComboboxStyledProps) {
  // console.log("options", options)
  const inputRef = useRef<any>(null)
  const buttonRef = useRef<any>(null)

  const {
    groupedOptions,
    selectedKeys,
    // optionsArray,
    handleInputChange,
    setSelectedKeys,
  } = useDropdown(options, sortOptions, defaultSelectedKeys)

  const [query, setQuery] = useState<string>("")
  const [isMaxed, setIsMaxed] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    updateIsMaxed(defaultSelectedKeys)
    if (defaultSelectedKeys) setSelectedKeys(defaultSelectedKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedKeys, maxSelectCount])

  function updateIsMaxed(keys: any) {
    setIsMaxed(maxSelectCount ? maxSelectCount <= keys.length : false)
  }

  const handleRemove = (key: any) => {
    if (disabled) return
    const newSelectedKeys = selectedKeys.filter((_key) => _key != key)
    updateIsMaxed(newSelectedKeys)
    setSelectedKeys(newSelectedKeys)
    if (getErrorMessage(newSelectedKeys)) setShowError(true)
    if (onSelect) onSelect(newSelectedKeys)
  }

  const handleKeyDown = (e: any) => {
    if (e.key == "Backspace" && query == "" && !disabled) {
      const newSelectedKeys = selectedKeys.slice(0, -1)
      updateIsMaxed(newSelectedKeys)
      setSelectedKeys(newSelectedKeys)
      if (getErrorMessage(newSelectedKeys)) setShowError(true)
    }
  }

  const handleSelection = (selected: any) => {
    const selectedKeys = (Array.isArray(selected) ? selected : [selected])?.map(
      (option: any) => option.key
    )
    const isMaxed = maxSelectCount ? maxSelectCount < selectedKeys.length : false
    updateIsMaxed(selectedKeys)
    if (!isMaxed) {
      setSelectedKeys(selectedKeys)
      onSelect?.(selectedKeys)
      simulateComboboxClick()
    }
  }

  const isBelowMinSelectCount = minSelectCount
    ? selectedKeys.length < minSelectCount
    : false

  const getErrorMessage = (selectedKeys: any) => {
    let message = ""
    if (required && isNullOrEmpty(selectedKeys)) message = "This field is required."
    else if (isBelowMinSelectCount)
      message = `Minimum ${minSelectCount} items needs to be selected.`
    return message
  }

  const errorMessage = getErrorMessage(selectedKeys)
  const isError = errorMessage && showError
  const selectedOptions = !isNullOrEmpty(options)
    ? selectedKeys.map((key) => options[key]).filter(Boolean)
    : []

  const containerClass = joinClassNames(
    "flex flex-col bg-transparent text-primary rounded-md relative w-full border text-left text-sm transition-colors delay-75",
    isError && "border-destructive",
    singleSelectMode ? " " : "px-2",
    disabled ? " opacity-50 cursor-not-allowed " : " cursor-pointer "
  )

  const simulateComboboxClick = () => {
    if (inputRef) {
      inputRef.current.value = ""
      setQuery("")
      handleInputChange("")
      inputRef.current.focus()
    }
  }

  const commonInputStyles = ` bg-transparent border-none text-sm group leading-5 text-primary focus:ring-0 px-0  w-full border rounded-md focus:outline-none placeholder:text-muted-foreground bg-muted  `

  const selectedItem =
    !isNullOrEmpty(selectedKeys) && options && options[selectedKeys[0]]
  return (
    <Label
      {...label}
      required={required}
      handleError={!!isError}
      error={errorMessage}
      id={id}
    >
      <Combobox
        value={singleSelectMode ? selectedOptions[0] : selectedOptions}
        onChange={handleSelection}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        multiple={singleSelectMode ? false : true}
        by="key"
        disabled={disabled}
      >
        {({ open }) => (
          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            className="relative col-span-3"
            onBlur={() => {
              if (errorMessage) setShowError(true)
            }}
            onFocus={() => {
              setShowError(false)
              inputRef.current?.focus()
            }}
          >
            <div className={containerClass}>
              {!isNullOrEmpty(selectedKeys) && !singleSelectMode && options && (
                <div className="py-2">
                  <Tags>
                    {selectedKeys.map((key) => (
                      <Tag
                        key={key}
                        cross={true}
                        onCrossClick={() => handleRemove(key)}
                      >
                        {options[key]?.display}
                      </Tag>
                    ))}
                  </Tags>
                </div>
              )}

              {!options && !query ? (
                <div className="h-[35px]">
                  <Loader />
                </div>
              ) : (
                <>
                  {singleSelectMode && selectedItem && !open ? (
                    <Combobox.Button>
                      {/* 1️⃣ ListBoxOption pretending to be a combobox in singleSelectMode */}
                      <ListBoxOption
                        onSelect={() => {
                          // 2️⃣ simulate click and wait for actual Combobox to render
                          buttonRef?.current?.click()
                          setTimeout(() => {
                            // 3️⃣ after split second delay when ListBoxOption is gone and Combobox text input is rendered
                            simulateComboboxClick()
                          })
                        }}
                        option={{ ...selectedItem, disabled: false }}
                        selected={false}
                        isHoverable={false}
                      />
                    </Combobox.Button>
                  ) : (
                    <div
                      className={`flex justify-start gap-2 items-center  ${
                        singleSelectMode ? " px-2 py-0 " : "  "
                      }`}
                    >
                      {open && (
                        <Svg
                          classes="w-4 h-4 animate-loadFadeUp text-secondary"
                          name="search"
                        />
                      )}
                      <Combobox.Input
                        className={commonInputStyles}
                        onClick={() => {
                          if (!open) {
                            buttonRef?.current?.click()
                            simulateComboboxClick()
                          }
                        }}
                        onChange={(e) => {
                          handleInputChange(e.target.value)
                          setQuery(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        placeholder={placeHolderText}
                        displayValue={(option: any) => option?.display}
                        id={id}
                      />
                      <Combobox.Button ref={buttonRef}>
                        <Svg
                          name="arrow-up-down"
                          classes="w-4 h- 4 animate-loadFadeUp text-secondary"
                        />
                      </Combobox.Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="relative">
              <Combobox.Options as="div" className="absolute w-full list-option ">
                <div className="relative">
                  {isNullOrEmpty(groupedOptions) && <EmptyList />}
                  {Object.entries(groupedOptions).map(([section, options]) => (
                    <div key={section}>
                      {section !== sectionException.other && (
                        <ListSection sectionName={section} />
                      )}
                      {options?.map((option) => {
                        const isDisabled =
                          option.disabled ||
                          disabled ||
                          (isMaxed && !selectedKeys.includes(option.key))
                        return (
                          <Combobox.Option
                            key={option.key}
                            className={({ active }) =>
                              getListedOptionClasses(active, isDisabled)
                            }
                            disabled={isDisabled}
                            value={option}
                          >
                            {({ selected }) => (
                              <div className="flex justify-start gap-2 items-center w-full">
                                <ListboxOptionContent
                                  icon={option.icon}
                                  display={option.display}
                                  selected={selected}
                                  showSelectedIcon={singleSelectMode}
                                  showCheckedIcon={!singleSelectMode}
                                />
                              </div>
                            )}
                          </Combobox.Option>
                        )
                      })}
                    </div>
                  ))}
                  {!disabled && handleOptionCreate && enableOptionCreate && (
                    <OptionForm
                      query={query}
                      handleOptionCreate={handleOptionCreate}
                    />
                  )}
                </div>
              </Combobox.Options>
            </div>
          </div>
        )}
      </Combobox>
    </Label>
  )
}
