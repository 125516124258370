import SearchBar from "components/common/searchBar"
import Loader from "@common/loader"
import StickyHeaderFooter, {
  Header,
  Main,
} from "../common/layouts/stickyHeaderFooter"
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroller"
import Text from "../../components/text"
import { useState, useEffect, createRef } from "react"
import { isArraysEqual, isEmpty, isNullOrEmpty, localSearch } from "./lib/util"
import { isAllSelected } from "./table/util"
import Skeleton from "@common/skeletons/skeleton"
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
// Reference for keyboard navigation https://stackoverflow.com/questions/42036865/react-how-to-navigate-through-list-by-arrow-keys

// searches over the value and not key.
// If key has to be searched over, add key to value and send data
/*
list = {
  'AZ_sdfdsf': 'product a brand y...' ,
  'AZ_sdfdsf': 'product a brand y...' ,
  'AZ_sdfdsf': 'product a brand y...' ,
}
*/

const PAGESIZE = 10

ListSearch.propTypes = {
  list: PropTypes.object,
  searchType: PropTypes.string, // "local" or "global". default: local.
  onSearch: PropTypes.func,
  renderListUnit: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  renderAdvancedFilters: PropTypes.func,
  emptyComponent: PropTypes.object,

  infiniteScroll: PropTypes.bool, // default false
  useWindow: PropTypes.bool, // default false. ie. expects a container with height. If true, it will scroll entire window.
}

export default function ListSearch({
  list,
  searchType,
  onMultiSelectList,
  renderAdvancedFilters,
  multiSelectList,
  emptyComponent,
  infiniteScroll = false,
  useWindow,
  renderListUnit,
  onSelect,
  onSearch,
  initSelectedKeys,
  placeholder = "Search"
}) {
  const [input, setInput] = useState()
  const [selectedKeys, setSelectedKeys] = useState(initSelectedKeys)
  const [resultKeys, setResultKeys] = useState(getKeys(list)) // creates an array with keys
  const [hasMore, setHasMore] = useState(true)
  const [start, setStart] = useState(0)
  // const [selected, setSelected] = useState(undefined)
  // const [hovered, setHovered] = useState(undefined)
  const [cursor, setCursor] = useState(-1)
  const searchBox = createRef()
  const downPress = useKeyPress("ArrowDown", searchBox)
  const upPress = useKeyPress("ArrowUp", searchBox)
  const enterPress = useKeyPress("Enter", searchBox)
  const isAllRowSelected = isAllSelected(resultKeys, selectedKeys)

  useEffect(() => {
    setSelectedKeys(initSelectedKeys)
  }, [initSelectedKeys])

  useEffect(() => {
    setResultKeys(getKeys(list))
    setStart(0)
    setHasMore(true)
    if (input) search(input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  useEffect(() => {
    if (searchType == "global") {
      const debounce = setTimeout(() => {
        search(input)
      }, 500)
      return () => clearTimeout(debounce)
    } else search(input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, searchType])

  useEffect(() => {
    if (resultKeys && resultKeys.length && downPress) {
      setCursor((prevState) =>
        prevState < resultKeys.length - 1 ? prevState + 1 : prevState
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress])

  useEffect(() => {
    if (resultKeys && resultKeys.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress])

  useEffect(() => {
    if (resultKeys && resultKeys.length && enterPress) {
      handleSelection(resultKeys[cursor])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, enterPress])

  const handleMultiSelection = (key) => {
    const currentSelectedKeys = selectedKeys || []

    const isSelected = currentSelectedKeys.includes(key)
    const rowKeys = isSelected
      ? currentSelectedKeys.filter((_key) => _key !== key)
      : [...currentSelectedKeys, key]
    setSelectedKeys(rowKeys)
    if (onMultiSelectList) onMultiSelectList(rowKeys)
  }

  const handleSelectAll = () => {
    let rowKeys = []
    if (!isAllRowSelected) rowKeys = resultKeys
    setSelectedKeys(rowKeys)
    if (onMultiSelectList) onMultiSelectList(rowKeys)
  }
  return (
    <StickyHeaderFooter>
      <Header>
        <div
          ref={searchBox}
          className="flex p-3 border-b bg-background divide-x divide-border"
        >
          <SearchBar placeholder={placeholder} onChange={(input) => setInput(input)} />
          {selectedKeys?.length > 0 && (
            <div className="text-sm my-auto pl-2">
              {selectedKeys.length} Selected
            </div>
          )}
        </div>
        {renderAdvancedFilters ? renderAdvancedFilters() : null}
        {!isNullOrEmpty(resultKeys) && multiSelectList && (
          <div className="mx-3 my-2">
            <CheckboxStyled
              checked={isAllRowSelected}
              onChange={handleSelectAll}
              id="selectAllButton"
              label={{
                label: !isNullOrEmpty(selectedKeys)
                ? `${selectedKeys.length} Selected `
                : `Select All`,
                labelPosition: "right"
              }}
            />
          </div>
        )}
      </Header>
      <Main>{renderListSection()} </Main>
    </StickyHeaderFooter>
  )

  function renderListSection() {
    if (!resultKeys) return <Skeleton name="ListSkeleton" />
    if (resultKeys.length == 0)
      return (
        emptyComponent || <Text className="p-4 text-center">No matches found.</Text>
      )

    if (infiniteScroll) {
      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={(page) => getMoreList(page)}
          hasMore={hasMore}
          loader={<Loader />}
          useWindow={useWindow || false}
        >
          {renderList()}
        </InfiniteScroll>
      )
    }

    return renderList()
  }

  function renderList() {
    const list = infiniteScroll ? resultKeys.slice(0, start + PAGESIZE) : resultKeys

    return list.map((key) => renderListItem(key))
  }

  function renderListItem(key) {
    const isSelected = selectedKeys?.includes(key)
    const isActive = resultKeys[cursor] == key
    return (
      <div key={key} onClick={() => handleSelection(key)}>
        {renderListUnit(key, isSelected, isActive)}
      </div>
    )
  }

  function handleSelection(key) {
    if (multiSelectList) handleMultiSelection(key)
    else if (onSelect && key) onSelect(key)
  }

  function getMoreList(page) {
    if (hasMore == true) {
      const newStart = page * PAGESIZE
      setStart(newStart)
      if (resultKeys.length < newStart + PAGESIZE) setHasMore(false)
    }
  }

  function search(input) {
    if (searchType === "global" && onSearch) {
      onSearch(input)
      return
    }
    if (!input) {
      setResultKeys(getKeys(list))
      setStart(0)
      setHasMore(true)
      return
    }

    const resultKeys = localSearch(input, list)
    setResultKeys(resultKeys)
  }

  function getKeys(list) {
    if (!list) return
    return Object.keys(list || {})
  }
}

const useKeyPress = function (targetKey, ref) {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    ref.current?.addEventListener("keydown", downHandler)
    ref.current?.addEventListener("keyup", upHandler)

    return () => {
      ref.current?.removeEventListener("keydown", downHandler)
      ref.current?.removeEventListener("keyup", upHandler)
    }
  })

  return keyPressed
}
