import { ErrorCodes } from "@common/lib/forms.utils"
import Svg from "./svg"

// size - xs, sm (default)
// success = true/false (default false)

export default function Message({ size, message, success }) {
  const textSize = size == "xs" ? "text-xs" : "text-sm"
  const svgSize = size == "xs" ? "w-4 h-4" : "w-6 h-6"
  const svgIcon = success == true ? "tick" : "warning"
  const color = success == true ? "text-green-500" : "text-red-500"

  return (
    <div className={"flex items-center max-h-full " + textSize + " " + color}>
      <Svg name={svgIcon} classes={svgSize + " mr-2"} />
      <div>{message || ErrorCodes["GENERAL"]}</div>
    </div>
  )
}
