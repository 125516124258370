import Label, { LabelProps } from "@common/forms/label"
import PaletteUnit from "@common/theme/paletteUnit"

type MultiColorPickerProps =  Pick<LabelProps, "required" | "id"> &  {
  colors: string[]
  colorsCount: number
  onChange: (pickedColors: any) => any
  label?: LabelProps
}
export default function MultiColorPicker({
  label={},
  colors,
  colorsCount,
  onChange,
  required,
  id,
}: MultiColorPickerProps) {
  const handleColorChange = (color: string, index: number) => {
    const updatedColors = colors
      ? [...colors]
      : new Array(colorsCount).fill(undefined)
    updatedColors[index] = color
    onChange(updatedColors)
  }

  const paletteCount = colors || new Array(colorsCount).fill(undefined)

  return (
    <Label {...label} required={required} id={id}>
      <div className="flex flex-wrap gap-2 md:gap-4 rounded-md border p-2 md:p-4">
        {paletteCount.map((colorCode, index) => (
          <div className="min-w-[8rem]" key={index}>
            <PaletteUnit
              colorCode={colorCode}
              onColorChange={(color) => handleColorChange(color, index)}
            />
          </div>
        ))}
      </div>
    </Label>
  )
}
