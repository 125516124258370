import React from "react"
import { isFunction } from "@common/lib/util"
import Label from "@common/forms/label"
import { RadioStyledProps } from "@common/forms/inputs/types"

export default function RadioStyled({
  label = {},
  required,
  id,
  ...rest
}: RadioStyledProps) {
  return (
    <Label {...label} required={required} id={id}>
      <div className="flex items-center">
        <input
          {...rest}
          id={id}
          type="radio"
          className="text-primary dark:text-muted bg-transparent cursor-pointer disabled:opacity-60 disabled:cursor-not-allowed focus:ring-0 focus:ring-offset-0 border-border focus:ring-transparent"
          required={required}
          readOnly={!isFunction(rest.onChange)}
        />
      </div>
    </Label>
  )
}
