import { RangeProps } from "@common/forms/inputs/types"
import Label from "@common/forms/label"
import { useEffect, useState } from "react"

export default function Range({
  value,
  min,
  max,
  step,
  onChange,
  label = {},
  required,
  id,
}: RangeProps) {
  const [rangeValue, setRangeValue] = useState<number>(value || step)

  useEffect(() => {
    if (value != rangeValue) {
      setRangeValue(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  function handleRangeChange(value: number) {
    setRangeValue(value)
    onChange?.(value)
  }

  return (
    <Label {...label} required={required} id={id}>
      <div className="flex justify-between text-xxs-r items-center gap-2 font-extrabold text-zinc-300 dark:text-zinc-600">
        <div>{min}</div>
        <input
          value={rangeValue}
          onInput={(event: any) => handleRangeChange(event?.target?.value)}
          type="range"
          min={min || 0}
          max={max}
          step={step}
          id={id}
          required={required}
          className="w-full h-1 bg-zinc-300 dark:bg-zinc-600 rounded-lg appearance-none cursor-pointer "
        />
        <div>{max}</div>
      </div>
      <div
        className="flex justify-end text-xs font-bold"
        style={{ width: `${(value / max) * 100}%` }}
      >
        {value}
      </div>
    </Label>
  )
}
